<template>
    <b-row class="rowbg">
        <b-col md="12">
            <b-card>
                <b-card-title>Privacy Statement</b-card-title>
                <b-card-text>
                    <h2> Privacy statement </h2>
                    <p><strong>Privacyverklaring Ridder en de Prins Entertainment</strong><br />Versie 03-04-2024</p>
                    <p>Ridder en de Prins Entertainment, gevestigd aan Edelhertakker 24, 3994 ER, Houten, Nederland, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.</p>
                    <p>Contactgegevens:<br /><b-link href="https://www.ridderendeprins.nl">https://www.ridderendeprins.nl</b-link><br />Slinger 2<br />3995 DE Houten<br />Nederland<br />030 - 207 702 7</p>
                    <p>Kristel de Groot is de Functionaris Gegevensbescherming van Ridder en de Prins Entertainment. Zij is te bereiken via <b-link href="mailto:kristel@ridderendeprins.nl.">kristel@ridderendeprins.nl</b-link></p>
                    <p><strong>Persoonsgegevens die wij verwerken</strong></p>
                    <p>Ridder en de Prins Entertainment verwerkt je persoonsgegevens doordat je gebruik maakt van onze diensten en/of omdat je deze gegevens zelf aan ons verstrekt.<br />Hieronder vind je een overzicht van de persoonsgegevens die wij verwerken:</p>
                    <p>- Voor- en achternaam<br />- Geslacht<br />- Leeftijd<br />- Adresgegevens<br />- Telefoonnummer<br />- E-mailadres<br />- Gegevens ingevuld in het Online Grote Boek<br />- Gegevens over jouw activiteiten op onze website<br />- Internetbrowser
                        en apparaat type</p>
                    <p><strong>Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</strong></p>
                    <p>Ridder en de Prins Entertainment verwerkt de volgende bijzondere en/of gevoelige persoonsgegevens van jou:<br />- gegevens van personen jonger dan 16 jaar. Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers
                        die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen,
                        om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als je er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact
                        met ons op via <b-link href="mailto:info@ridderendeprins.nl">info@ridderendeprins.nl</b-link> , dan verwijderen wij deze informatie.</p>
                    <p><strong>Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</strong></p>
                    <p>Ridder en de Prins Entertainment verwerkt jouw persoonsgegevens voor de volgende doelen:<br />- Het afhandelen van jouw betaling<br />- Je te kunnen bellen of e-mailen indien dit
                        nodig is om onze dienstverlening uit te kunnen voeren<br />- Je te informeren over wijzigingen van onze diensten en producten<br />- Je de mogelijkheid te bieden een account aan te maken<br />- Om goederen en diensten bij je af te leveren Geautomatiseerde besluitvorming</p>
                    <p><br> Ridder en de Prins Entertainment neemt op basis van geautomatiseerde verwerkingen geen besluiten over zaken die (aanzienlijke) gevolgen kunnen hebben voor personen. Het gaat hier om besluiten die worden genomen door computerprogrammas
                        of -systemen, zonder dat daar een mens (bijvoorbeeld een medewerker van Ridder en de Prins Entertainment) tussen zit.</p>
                    <p><strong>Hoe lang we persoonsgegevens bewaren</strong></p>
                    <p>Ridder en de Prins Entertainment bewaart je persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor je gegevens worden verzameld. Wij hanteren de volgende bewaartermijnen voor de volgende (categorieën) van
                        persoonsgegevens:
                    </p>
                    <table border="1" cellspacing="0" cellpadding="0">
                        <tbody>
                            <tr>
                                <td><strong>Gegevens</strong></td>
                                <td><strong>Termijn</strong></td>
                                <td><strong>Reden</strong></td>
                            </tr>
                            <tr>
                                <td>Informatie gevuld in het Online Grote Boek over aanwezigen</td>
                                <td>Tot twee maanden na de show</td>
                                <td>Ten behoeve van het uitvoeren van de show</td>
                            </tr>
                            <tr>
                                <td>Geanonimiseerde informatie gevuld in het Online Grote Boek over aanwezigen</td>
                                <td>5 jaar</td>
                                <td>Ten behoeve van bedrijfsmatige analyse</td>
                            </tr>
                            <tr>
                                <td>Informatie gevuld in het Online Grote Boek over het bezoek</td>
                                <td>5 jaar</td>
                                <td>Ten behoeve van facturatieproces en optimaliseren werkproces</td>
                            </tr>
                            <tr>
                                <td>Contactgegevens Show</td>
                                <td>5 jaar</td>
                                <td>Ten behoeve van facturatieproces en optimaliseren werkproces</td>
                            </tr>
                            <tr>
                                <td>Geanonimiseerde gebruikersgegevens Appventure</td>
                                <td>1 jaar</td>
                                <td>Optimaliseren gebruik en werking app</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>&nbsp;</p>
                    <p><strong>Delen van persoonsgegevens met derden</strong></p>
                    <p>Ridder en de Prins Entertainment deelt jouw persoonsgegevens met verschillende derden als dit noodzakelijk is voor het uitvoeren van de overeenkomst en om te voldoen aan een eventuele wettelijke verplichting. Met bedrijven die je gegevens
                        verwerken in onze opdracht, sluiten wij een vewerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van jouw gegevens. Ridder en de Prins Entertainment blijft verantwoordelijk voor deze verwerkingen.
                        Daarnaast verstrekt Ridder en de Prins Entertainment jouw persoonsgegevens aan andere derden. Dit doen wij alleen met jouw nadrukkelijke toestemming.</p>
                    <table border="1" cellspacing="0" cellpadding="0">
                        <tbody>
                            <tr>
                                <td><strong>Categorie</strong></td>
                                <td><strong>Categorie derde partij</strong></td>
                                <td><strong>Doel</strong></td>
                            </tr>
                            <tr>
                                <td>Informatie in Online Grote Boek</td>
                                <td>Hosting partij</td>
                                <td>Optimaal functioneren website</td>
                            </tr>
                            <tr>
                                <td>Informatie in Online Grote Boek m.b.t. cadeaus</td>
                                <td>Leverancier Cadeaus</td>
                                <td>Leveren cadeaus ten behoeve van show</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>&nbsp;</p>
                    <p><strong>Cookies, of vergelijkbare technieken, die wij gebruiken</strong></p>
                    <p>Ridder en de Prins Entertainment gebruikt geen cookies of vergelijkbare technieken.</p>
                    <p><strong>Gegevens inzien, aanpassen of verwijderen</strong></p>
                    <p>Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heb je het recht om je eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van jouw persoonsgegevens
                        door Ridder en de Prins Entertainment en heb je het recht op gegevensoverdraagbaarheid. Dat betekent dat je bij ons een verzoek kan indienen om de persoonsgegevens die wij van jou beschikken in een computerbestand naar jou of een ander,
                        door jou genoemde organisatie, te sturen.Je kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van je persoonsgegevens of verzoek tot intrekking van je toestemming of bezwaar op de verwerking van jouw persoonsgegevens
                        sturen naar <b-link href="mailto:info@ridderendeprins.nl">info@ridderendeprins.nl</b-link> .
                        Om er zeker van te zijn dat het verzoek tot inzage door jou is gedaan, vragen wij jou een kopie van je identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie je pasfoto, MRZ (machine readable zone, de strook met nummers
                        onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van je privacy. We reageren zo snel mogelijk, maar binnen vier weken, op jouw verzoek . Ridder en de Prins Entertainment wil je er tevens
                        op wijzen dat je de mogelijkheid hebt om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link:<br /><b-link href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons" target="_blank">https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons</b-link></p>
                    <p><strong>Hoe wij persoonsgegevens beveiligen</strong></p>
                    <p>Ridder en de Prins Entertainment neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als jij het idee
                        hebt dat jouw gegevens toch niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via <b-link href="mailto:info@ridderendeprins.nl">info@ridderendeprins.nl</b-link>
                    </p>
<!--              <p>Deze privacy statement is ook beschikbaar als <b-link :href='$imagestore1+"/legal/Privacyverklaring_RedP_28-01-2020.pdf"' target="_blank">download</b-link>.</p>
   -->                
                </b-card-text>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    export default {
        name: 'Privacy'
    }
</script>

<style>

</style>